@font-face {
  font-family: 'Avenir';
  src: local('Avenir'),
    url(./assets/Fonts/AvenirLTStd-Book.otf) format(opentype);
}

.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-Title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  margin: 0px;
  padding: 40px;
  padding-bottom: 20px;
}

.App-Subtitle {
  font-style: normal;
  font-weight: 350;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.05em;
  margin: 0px;
  padding-top: 100px;
  text-align: center;
}

.NavBar {
  margin-left: 40px;
  margin-right: 40px;
  background: #f5f5f5;
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.NavItem {
  font-size: 20px;
  padding: 20px;
  text-decoration: none;
  color: black;
}

.Content-Wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

.Main-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Summary {
  width: 50vw;
  text-align: center;
  font-size: 16px;
}

.Image-Content {
  width: 20vw;
  height: 20vh;
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

.Icons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.Icon {
  padding: 15px;
}

.wip {
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 3px;
}
